import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  Welcome,
  WhatIsQargoquote,
  Story,
  Logistics,
  Other,
  KeyBenefits,
  HowItWorks,
  Importer,
  HowItWorksSecond,
  LogisticsIntro,
  KeyBenefitsS,
  GetStarted,
  Faqs,
} from "../components";

export const MainPage = () => {
  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>QargoQuote | Simplify Your Logistics</title>
          <meta
            name="description"
            content="Discover how QargoQuote helps businesses find, compare, and manage logistics quotes efficiently. Simplify your logistics today!"
          />
          <meta
            name="keywords"
            content="logistics, transport, quotes, shipping, QargoQuote"
          />
          <meta name="author" content="QargoQuote Team" />
          <link rel="canonical" href="https://qargoquote1.netlify.app/" />
        </Helmet>
        <main>
          <Welcome />
          <WhatIsQargoquote />
          <Story />
          <Logistics />
          <Other />
          <KeyBenefits />
          <HowItWorks />
          <Importer />
          <LogisticsIntro />
          <HowItWorksSecond />
          <KeyBenefitsS />
          <Faqs />
          <GetStarted />
        </main>
      </>
    </HelmetProvider>
  );
};
