import React, { useState } from "react";
import { FaRegCommentDots, FaPhoneAlt } from "react-icons/fa";
import useContactUs from "../hooks/useContactUs";

export const Form = ({ isFormOpen, setIsFormOpen }) => {
  const toggleForm = () => setIsFormOpen(!isFormOpen);

  // Use the custom hook
  const { sendContactUs, loading, error, success } = useContactUs();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await sendContactUs(formData);
  };

  return (
    <div className="relative">
      {/* Floating Chat Icon */}
      <div
        className="fixed bottom-4 right-4 p-4 bg-white text-white rounded-full cursor-pointer shadow-lg"
        onClick={toggleForm}
      >
        <FaRegCommentDots size={24} className="text-rose-900" />
      </div>

      {/* Contact Us Form */}
      {isFormOpen && (
        <div className="fixed bottom-20 right-4 w-72 p-6 bg-white shadow-lg rounded-lg">
          <h2 className="text-lg font-bold mb-4 text-gray-800">Contact Us</h2>

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-medium mb-2">
                Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Your Name"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-medium mb-2">
                Phone Number
              </label>
              <input
                type="tel"
                name="number"
                value={formData.number}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="ex: 010.........."
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-medium mb-2">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Your Email"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-medium mb-2">
                How Can We Help?
              </label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Your Message"
                rows="4"
              ></textarea>
            </div>

            <div className="mb-4 flex items-center space-x-2">
              <FaPhoneAlt size={20} className="text-green-500" />
              <a
                href="tel:+97444294865"
                className="text-gray-700 text-sm font-medium hover:underline"
              >
                +974 4429 4865
              </a>
            </div>

            <button
              type="submit"
              className="w-full bg-rose-900 text-white py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              disabled={loading}
            >
              {loading ? "Sending..." : "Send"}
            </button>

            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            {success && (
              <p className="text-green-500 text-sm mt-2">{success}</p>
            )}
          </form>
        </div>
      )}
    </div>
  );
};

export default Form;
