import { useState } from "react";

const useContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const sendContactUs = async (formData) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await fetch("https://api.qargoquote.com/api/v1/contactus", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          content: formData.message,
          phone: formData.number,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess("Message sent successfully!");
      } else {
        setError(data.message || "Failed to send message.");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return { sendContactUs, loading, error, success };
};

export default useContactUs;
