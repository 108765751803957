import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { SOP1 } from "../components";

export const SOP = () => {
  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>Standard Operating Procedures | QargoQuote</title>
          <meta
            name="description"
            content="Learn about QargoQuote's Standard Operating Procedures to ensure efficient and reliable logistics solutions."
          />
          <meta
            name="keywords"
            content="SOP, logistics, procedures, QargoQuote, operations"
          />
          <link rel="canonical" href="https://qargoquote1.netlify.app/SOP" />
        </Helmet>
        <main>
          <SOP1 />
        </main>
      </>
    </HelmetProvider>
  );
};
